<template>
  <div
    class="conversation"
    :class="{
      active: isActiveChat,
      'unread-chat': hasUnread,
      'has-inbox-name': showInboxName,
      'conversation-selected': selected,
    }"
    @mouseenter="onCardHover"
    @mouseleave="onCardLeave"
    @click="cardClick(chat)"
    @contextmenu="openContextMenu($event)"
  >
    <label v-if="hovered || selected" class="checkbox-wrapper" @click.stop>
      <input
        :value="selected"
        :checked="selected"
        class="checkbox"
        type="checkbox"
        @change="onSelectConversation($event.target.checked)"
      />
    </label>
    <thumbnail
      v-if="bulkActionCheck"
      :src="currentContact.thumbnail"
      :badge="inboxBadge"
      class="columns"
      :username="currentContact.name"
      :status="currentContact.availability_status"
      size="40px"
    />
    <div class="conversation--details columns">
      <div class="conversation--metadata">
        <inbox-name v-if="showInboxName" :inbox="inbox" />
        <inbox-agent-name :name-agent="assignee.name" />
      </div>
      <h4 class="conversation--user">
        &ZeroWidthSpace;{{ currentContact.name || '&nbsp;' }}
      </h4>
      <p v-if="lastMessageInChat" :class="callLastMessageClass">
        <fluent-icon
          v-if="
            lastMessageInChat.content_attributes &&
              lastMessageInChat.content_attributes.items &&
              lastMessageInChat.content_attributes.items.length > 0
          "
          size="16"
          class="message--attachment-icon interactive-message-icon-class"
          icon="interactive-message-icon"
        />
        <fluent-icon
          v-else-if="isMessagePrivate"
          size="16"
          class="message--attachment-icon last-message-icon"
          icon="lock-closed"
        />
        <fluent-icon
          v-else-if="shouldShowCallSpan"
          :size="20"
          :class="callIconClass"
          :icon="getCallIcon"
          type="solid"
          viewbox="0 0 20 20"
        />
        <fluent-icon
          v-else-if="messageByAgent"
          size="16"
          class="message--attachment-icon last-message-icon"
          icon="arrow-reply"
        />
        <fluent-icon
          v-else-if="isMessageAnActivity"
          size="16"
          class="message--attachment-icon last-message-icon"
          icon="info"
        />
        <span
          v-if="
            lastMessageInChat.content_attributes &&
              lastMessageInChat.content_attributes.items &&
              lastMessageInChat.content_attributes.items.length > 0
          "
        >
          {{ $t('CHAT_LIST.INTERACTIVE_MESSAGE') }}
        </span>
        <span v-else-if="shouldShowCallSpan" class="call_last_message">
          {{ parsedLastMessage }}
        </span>

        <span v-else-if="lastMessageInChat.content">
          {{ parsedLastMessage }}
        </span>
        <span v-else-if="lastMessageInChat.attachments">
          <fluent-icon
            v-if="attachmentIcon"
            size="16"
            class="message--attachment-icon"
            :icon="attachmentIcon"
          />
          {{ attachmentMessageContent }}
        </span>
        <span v-else-if="inboxBadge === 'instagram_direct_message'">
          {{
            this.$t('CONVERSATION.NO_SUPPORTED_MESSAGE', {
              channel: 'Instagram',
            })
          }}
        </span>
        <span v-else>
          {{ $t('CHAT_LIST.NO_CONTENT') }}
        </span>
      </p>
      <p v-else class="conversation--message">
        <fluent-icon size="16" class="message--attachment-icon" icon="info" />
        <span>
          {{ this.$t(`CHAT_LIST.NO_MESSAGES`) }}
        </span>
      </p>
      <div class="conversation--meta">
        <span class="timestamp">
          <time-ago
            :last-activity-timestamp="chat.timestamp"
            :created-at-timestamp="chat.created_at"
          />
        </span>
        <span class="unread">{{ unreadCount > 99 ? '99+' : unreadCount }}</span>
      </div>
      <card-labels :conversation-id="chat.id" />
    </div>
    <woot-context-menu
      v-if="showContextMenu"
      ref="menu"
      :x="contextMenu.x"
      :y="contextMenu.y"
      @close="closeContextMenu"
    >
      <conversation-context-menu
        :status="chat.status"
        :inbox-id="inbox.id"
        :has-unread-messages="hasUnread"
        @update-conversation="onUpdateConversation"
        @assign-agent="onAssignAgent"
        @assign-label="onAssignLabel"
        @assign-team="onAssignTeam"
        @mark-as-unread="markAsUnread"
      />
    </woot-context-menu>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import {
  MESSAGE_TYPE,
  MESSAGE_CONTENT_TYPE,
  CALL_ICONS,
  CALL_ICON_CLASS,
  CALL_TYPES,
} from 'widget/helpers/constants';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import Thumbnail from '../Thumbnail';
import conversationMixin from '../../../mixins/conversations';
import timeMixin from '../../../mixins/time';
import router from '../../../routes';
import { frontendURL, conversationUrl } from '../../../helper/URLHelper';
import InboxName from '../InboxName';
import InboxAgentName from '../InboxAgentName.vue';
import inboxMixin from 'shared/mixins/inboxMixin';
import ConversationContextMenu from './contextMenu/Index.vue';
import alertMixin from 'shared/mixins/alertMixin';
import TimeAgo from 'dashboard/components/ui/TimeAgo';
import CardLabels from './conversationCardComponents/CardLabels.vue';
import { CHANNEL_TYPE, CHANNEL } from 'shared/constants/messages';
const ATTACHMENT_ICONS = {
  image: 'image',
  audio: 'headphones-sound-wave',
  video: 'video',
  file: 'document',
  location: 'location',
  fallback: 'link',
  share: 'document',
};

export default {
  components: {
    CardLabels,
    InboxName,
    Thumbnail,
    ConversationContextMenu,
    TimeAgo,
    InboxAgentName,
  },

  mixins: [
    inboxMixin,
    timeMixin,
    conversationMixin,
    messageFormatterMixin,
    alertMixin,
  ],
  props: {
    activeLabel: {
      type: String,
      default: '',
    },
    chat: {
      type: Object,
      default: () => {},
    },
    hideInboxName: {
      type: Boolean,
      default: false,
    },
    hideThumbnail: {
      type: Boolean,
      default: false,
    },
    teamId: {
      type: [String, Number],
      default: 0,
    },
    foldersId: {
      type: [String, Number],
      default: 0,
    },
    showAssignee: {
      type: Boolean,
      default: false,
    },
    conversationType: {
      type: String,
      default: '',
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hovered: false,
      showContextMenu: false,
      contextMenu: {
        x: null,
        y: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      inboxesList: 'inboxes/getInboxes',
      activeInbox: 'getSelectedInbox',
      currentUser: 'getCurrentUser',
      accountId: 'getCurrentAccountId',
    }),
    /**
     * Comprueba si el último mensaje en el chat es una llamada perdida entrante.
     *
     * @function
     * @name isMissedCallIncoming
     * @returns {boolean} - Verdadero si el último mensaje es una llamada perdida entrante, falso en caso contrario.
     */
    isMissedCallIncoming() {
      return (
        this.lastMessageInChat.message_type === MESSAGE_TYPE.INCOMING &&
        this.lastMessageInChat.content_type === MESSAGE_CONTENT_TYPE.MISSED_CALL
      );
    },

    /**
     * Comprueba si el último mensaje en el chat es una llamada perdida saliente.
     *
     * @function
     * @name isMissedCallOutgoing
     * @returns {boolean} - Verdadero si el último mensaje es una llamada perdida saliente, falso en caso contrario.
     */
    isMissedCallOutgoing() {
      return (
        this.lastMessageInChat.message_type === MESSAGE_TYPE.OUTGOING &&
        this.lastMessageInChat.content_type === MESSAGE_CONTENT_TYPE.MISSED_CALL
      );
    },
    isAnsweredCallIncoming() {
      return (
        this.lastMessageInChat.message_type === MESSAGE_TYPE.INCOMING &&
        this.lastMessageInChat.content_type ===
          MESSAGE_CONTENT_TYPE.ANSWERED_CALL
      );
    },
    isAnsweredCallOutgoing() {
      return (
        this.lastMessageInChat.message_type === MESSAGE_TYPE.OUTGOING &&
        this.lastMessageInChat.content_type ===
          MESSAGE_CONTENT_TYPE.ANSWERED_CALL
      );
    },
    /**
     * Comprueba si el último mensaje en el chat corresponde a una llamada en proceso de inicio.
     * @returns {boolean} - `true` si es una llamada en proceso de inicio, `false` en caso contrario.
     */
    isStartingCall() {
      return (
        this.lastMessageInChat.content_type ===
        MESSAGE_CONTENT_TYPE.STARTING_CALL
      );
    },
    /**
     * Comprueba si el último mensaje en el chat corresponde a una llamada con error.
     * @returns {boolean} - `true` si es una llamada con error, `false` en caso contrario.
     */
    isErrorCall() {
      return (
        this.lastMessageInChat.content_type === MESSAGE_CONTENT_TYPE.ERROR_CALL
      );
    },
    /**
     * Obtiene el icono adecuado para el tipo de llamada.
     * @returns {string} - El nombre del icono.
     */
    getCallIcon() {
      if (this.isMissedCallIncoming) return CALL_ICONS.MISSED_CALL_INCOMING;
      if (this.isMissedCallOutgoing) return CALL_ICONS.MISSED_CALL_OUTGOING;
      if (this.isAnsweredCallIncoming) return CALL_ICONS.ANSWERED_CALL_INCOMING;
      if (this.isAnsweredCallOutgoing) return CALL_ICONS.ANSWERED_CALL_OUTGOING;
      if (this.isStartingCall) return CALL_ICONS.STARTING_CALL;
      if (this.isErrorCall) return CALL_ICONS.ERROR_CALL;
      return '';
    },
    /**
     * Obtiene el color adecuado para el icono de llamada.
     * @returns {string} - La clase de color del icono.
     */
    callIconClass() {
      if (
        this.isMissedCallIncoming ||
        this.isMissedCallOutgoing ||
        this.isErrorCall
      ) {
        return CALL_ICON_CLASS.MISSED_CALL_ICON;
      }
      if (
        this.isAnsweredCallIncoming ||
        this.isAnsweredCallOutgoing ||
        this.isStartingCall
      ) {
        return CALL_ICON_CLASS.CALL_ICON;
      }
      return '';
    },

    /**
     * Obtiene la clase CSS para el elemento de mensaje en función del tipo de mensaje.
     *
     * @function
     * @name callLastMessageClass
     * @returns {string} - La clase CSS correspondiente al tipo de mensaje.
     *
     * @example
     * // Devuelve 'conversation--message-call-text' si es una llamada, de lo contrario, 'conversation--message'.
     * const messageClass = callLastMessageClass();
     */
    callLastMessageClass() {
      if (this.isCall()) return 'conversation--message-call-text';
      return 'conversation--message';
    },
    bulkActionCheck() {
      return !this.hideThumbnail && !this.hovered && !this.selected;
    },
    chatMetadata() {
      return this.chat.meta || {};
    },

    assignee() {
      return this.chatMetadata.assignee || {};
    },
    /**
     * Determina si se debe mostrar el span de llamada (call).
     *
     * @function
     * @name shouldShowCallSpan
     * @returns {boolean} - Devuelve verdadero si se debe mostrar el span de llamada, falso en caso contrario.
     */
    shouldShowCallSpan() {
      if (!this.lastMessageInChat) {
        return false;
      }
      return CALL_TYPES.includes(this.lastMessageInChat.content_type);
    },
    currentContact() {
      return this.$store.getters['contacts/getContact'](
        this.chatMetadata.sender.id
      );
    },

    lastMessageFileType() {
      const lastMessage = this.lastMessageInChat;
      const [{ file_type: fileType } = {}] = lastMessage.attachments;
      return fileType;
    },

    attachmentIcon() {
      if (!this.hasAttachmentUrl) {
        return '';
      }
      return ATTACHMENT_ICONS[this.lastMessageFileType];
    },

    attachmentMessageContent() {
      if (!this.hasAttachmentUrl) {
        return this.$t('CONVERSATION.NO_SUPPORTED_MESSAGE', {
          channel: this.channelTypeDescription,
        });
      }

      return this.$t(
        `CHAT_LIST.ATTACHMENTS.${this.lastMessageFileType}.CONTENT`
      );
    },

    hasAttachmentUrl() {
      const lastMessage = this.lastMessageInChat;
      const [{ data_url: dataUrl } = {}] = lastMessage.attachments;
      return dataUrl !== null;
    },

    channelTypeDescription() {
      if (
        this.channelType === CHANNEL_TYPE.FACEBOOK &&
        this.conversationType === ''
      )
        return CHANNEL.FACEBOOK;
      if (this.channelType === CHANNEL_TYPE.WHATSAPP) return CHANNEL.WHATSAPP;
      if (this.conversationType === 'instagram_direct_message')
        return CHANNEL.INSTAGRAM;

      return this.$t('CONVERSATION.UNIDENTIFIED_CHANNEL');
    },

    isActiveChat() {
      return this.currentChat.id === this.chat.id;
    },

    unreadCount() {
      return this.chat.unread_count;
    },

    hasUnread() {
      return this.unreadCount > 0;
    },

    isInboxNameVisible() {
      return !this.activeInbox;
    },

    lastMessageInChat() {
      return this.lastMessage(this.chat);
    },

    messageByAgent() {
      const lastMessage = this.lastMessageInChat;
      const { message_type: messageType } = lastMessage;
      return messageType === MESSAGE_TYPE.OUTGOING;
    },

    isMessageAnActivity() {
      const lastMessage = this.lastMessageInChat;
      const { message_type: messageType } = lastMessage;
      return messageType === MESSAGE_TYPE.ACTIVITY;
    },

    isMessagePrivate() {
      const lastMessage = this.lastMessageInChat;
      const { private: isPrivate } = lastMessage;
      return isPrivate;
    },
    /**
     * Devuelve el contenido parseado del último mensaje en el chat.
     *
     * @returns {string} El contenido parseado del último mensaje.
     */
    parsedLastMessage() {
      const { content_attributes: contentAttributes } = this.lastMessageInChat;
      // Verificar si la propiedad content_type contiene la palabra "call"
      if (
        this.lastMessageInChat.content_type &&
        this.lastMessageInChat.content_type.includes('call')
      ) {
        const { title, detail } = contentAttributes || {};
        const plainText = `${title || ''} ${detail || ''}`;
        return this.getPlainText(plainText);
      }
      // Si la propiedad content_type no contiene la palabra "call", usar el contenido directamente
      const { email: { subject } = {} } = contentAttributes || {};
      return this.getPlainText(subject || this.lastMessageInChat.content);
    },

    inbox() {
      const { inbox_id: inboxId } = this.chat;
      const stateInbox = this.$store.getters['inboxes/getInbox'](inboxId);
      return stateInbox;
    },

    showInboxName() {
      return (
        !this.hideInboxName &&
        this.isInboxNameVisible &&
        this.inboxesList.length > 1
      );
    },
    inboxName() {
      const stateInbox = this.inbox;
      return stateInbox.name || '';
    },
  },
  methods: {
    /**
     * Comprueba si el último mensaje en el chat es un tipo de llamada.
     *
     * @function
     * @name isCall
     * @returns {boolean} - Verdadero si el último mensaje es una llamada, de lo contrario, falso.
     *
     * @example
     * // Devuelve verdadero si el último mensaje es una llamada (missed_call o call), de lo contrario, falso.
     * const esLlamada = isCall();
     */
    isCall() {
      if (!this.lastMessageInChat) {
        return false;
      }
      return CALL_TYPES.includes(this.lastMessageInChat.content_type);
    },
    cardClick(chat) {
      const { activeInbox } = this;
      const path = conversationUrl({
        accountId: this.accountId,
        activeInbox,
        id: chat.id,
        label: this.activeLabel,
        teamId: this.teamId,
        foldersId: this.foldersId,
        conversationType: this.conversationType,
      });
      if (this.isActiveChat) {
        return;
      }
      router.push({ path: frontendURL(path) });
    },
    onCardHover() {
      this.hovered = !this.hideThumbnail;
    },
    onCardLeave() {
      this.hovered = false;
    },
    onSelectConversation(checked) {
      const action = checked ? 'select-conversation' : 'de-select-conversation';
      this.$emit(action, this.chat.id, this.inbox.id);
    },
    openContextMenu(e) {
      e.preventDefault();
      this.$emit('context-menu-toggle', true);
      this.contextMenu.x = e.pageX || e.clientX;
      this.contextMenu.y = e.pageY || e.clientY;
      this.showContextMenu = true;
    },
    closeContextMenu() {
      this.$emit('context-menu-toggle', false);
      this.showContextMenu = false;
      this.contextMenu.x = null;
      this.contextMenu.y = null;
    },
    onUpdateConversation(status, snoozedUntil) {
      this.closeContextMenu();
      this.$emit(
        'update-conversation-status',
        this.chat.id,
        status,
        snoozedUntil
      );
    },
    async onAssignAgent(agent) {
      this.$emit('assign-agent', agent, [this.chat.id]);
      this.closeContextMenu();
    },
    async onAssignLabel(label) {
      this.$emit('assign-label', [label.title], [this.chat.id]);
      this.closeContextMenu();
    },
    async onAssignTeam(team) {
      this.$emit('assign-team', team, this.chat.id);
      this.closeContextMenu();
    },
    async markAsUnread() {
      this.$emit('mark-as-unread', this.chat.id);
      this.closeContextMenu();
    },
  },
};
</script>

<style lang="scss" scoped>
.missed-call-icon {
  display: inline-block;
  vertical-align: middle;
  font-size: 20px;
  color: #cc2639;
}

.call-icon {
  display: inline-block;
  vertical-align: middle;
  font-size: 20px;
  color: #234e9c;
}

.conversation {
  align-items: flex-start;

  &:hover {
    background: var(--color-background-light);
  }

  &::v-deep .user-thumbnail-box {
    margin-top: var(--space-large);
  }
}

.conversation-selected {
  background: var(--color-background-light);
}

.conversation--details {
  .conversation--user {
    padding-top: var(--space-micro);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 60%;
  }
}

.last-message-icon {
  color: var(--s-600);
}

.conversation--metadata {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  padding-right: var(--space-normal);
}

.message--attachment-icon {
  margin-top: var(--space-minus-micro);
  vertical-align: middle;
}

.checkbox-wrapper {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-top: var(--space-large);
  cursor: pointer;

  &:hover {
    background-color: var(--w-100);
  }

  input[type='checkbox'] {
    margin: var(--space-zero);
    cursor: pointer;
  }
}

.conversation--message-call-text {
  font-size: 14px;
  font-weight: var(--font-weight-normal);
  letter-spacing: 0px;
  text-align: left;
  color: #37546d;
  height: 2rem;
  line-height: 2rem;
  display: inline-block;
  white-space: nowrap;
  text-overflow: unset;
  margin: 0;
  width: 90%;
}
.call_last_message {
  text-align: left;
  font-size: 14px;
  font-weight: var(--font-weight-normal);
  line-height: 20px;
  letter-spacing: 0px;
  height: 2rem;
  display: inline-block;
  vertical-align: middle;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  width: 90%;
}
.interactive-message-icon {
  vertical-align: middle;
}
</style>
